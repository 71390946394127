body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow: hidden;
  height: 100%;
}
#root {
  height: 100%;
}
